<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
        <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn medium color="primary" @click="btnsearch" class="mr-1">
            <v-icon left>mdi-reload</v-icon>LOAD DATA</v-btn
          >

<export-excel
            v-if="desserts.length > 0"
            :data="desserts"
            type="button"
          >
            <v-btn color="success"
              ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
            >
          </export-excel>
        </v-card-actions>
      <v-row>
      
          <!-- <v-select
            label="Select Company"
            v-model="SelCompany"
            :items="Company"
            item-text="company_name"
            item-value="company_id"
            dense
            outlined
            hide-details
          ></v-select> -->
        

        <!-- <v-col cols="12" md="3">
          <v-select
            label="Select Status"
            v-model="Selstatus"
            :items="Status"
            item-text="status_named"
            item-value="status_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col> -->

        <v-col cols="12" md="2">
         
        </v-col>

        <v-col cols="12" md="2">
       

          
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-1 mb-2" flat>
      <v-data-table
        v-model="TDselected"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="application_id"
        :loading="loadingstatus"
        loading-text="Loading... Please wait"
        style="border-radius: 0px"
      >
        <!-- @click="BtnApprove(index)" -->
        <templete v-slot:item.application_id="{ item }">
          <td class="tdalign">{{ item.sr_no }}</td>
          <td class="tdalign">{{ item.app_id }}</td>
          <td class="tdalign">{{ item.firstName }}</td>
          <td class="tdalign">{{ item.lastName }}</td>
          <td class="tdalign">{{ item.mediaName }}</td>
          <td class="tdalign">{{ item.role_name }}</td>
          <td class="tdalign">{{ item.print_text }}</td>
          <td class="tdalign">{{ item.mobile }}</td>
          <td class="tdalign">{{ item.email }}</td>
           <td class="tdalign">{{ item.venue_name }}</td>
          <td class="tdalign">{{ item.mediaType_name }}</td>
          <td class="tdalign">{{ item.status_named }}</td>
          <td class="tdalign">{{ item.reprintCount }}</td>

          
        </templete>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar v-model="snackbar" shaped top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
//import ViewApplication from "../../components/ViewApplication.vue";
export default {
  data() {
    return {
      TDselected: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: true,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

      Selstatus: "8",

      SelstatusForUpade: "",

      SelReasonForUpdate: "",
      ResonForUpdate: [],

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],
      strcheckatatus: "",

      SelCompany: "",
      Company: [],

      //companytype: [],
      companytype_id: [],

      headers: [
        {
          text: "Sr_no",
          align: "center",
          value: "sr_no",
          class: "blue lighten-5",
        },

        {
          text: "App-ID",
          value: "app_id",
          align: "center",
          class: "blue lighten-5",
        },

        {
          text: "First Name",
          value: "firstName",
          align: "center",
          class: "blue lighten-5",
        },

        {
          text: "Last Name",
          value: "lastName",
          align: "center",
          class: "blue lighten-5",
        },

         {
          text: "Media Name",
          value: "mediaName",
          align: "center",
          class: "blue lighten-5",
        },

        {
          text: "Designation",
          value: "role_name",
          align: "center",
          class: "blue lighten-5",
        },

        {
          text: "Zone/Access",
          value: "print_text",
          align: "center",
          class: "blue lighten-5",
        },

        {
          text: "Mobile",
          value: "mobile",
          align: "center",
          class: "blue lighten-5",
        },

        {
          text: "Email",
          value: "email",
          align: "center",
          class: "blue lighten-5",
        },

         {
           text: "PreferedCity",
          value: "venue_name",
          align: "center",
           class: "blue lighten-5",
         },
        {
          text: "Card",
          value: "mediaType_name",
          align: "center",
          class: "blue lighten-5",
        },
        {
          text: "Status",
          value: "status_named",
          align: "center",
          class: "blue lighten-5",
        },

         {
          text: "ReprintCount",
          value: "reprintCount",
          align: "center",
          class: "blue lighten-5",
        },

        
       
      ],
      desserts: [],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
    sessionStorage.display_event_name = "IPL T20 2021";
    sessionStorage.display_venue_name = "Chinaswamy Stadium";

    this.$store.commit("setPageTitle", "Media Basic Report");
   // this.$store.commit("setEventName", sessionStorage.display_event_name);
    //this.$store.commit("setVenueName", sessionStorage.display_venue_name);

   
    //  this.bindReson("1");
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    // watch: {
    //   chkselected: function () {
    //     // this.sheet=true;
    //     if (this.chkselected.length > 0) {
    //       alert("greater");
    //     }
    //   },
    // },

    chkall: function () {
      alert("test");
      // this.bindReson(this.SelstatusForUpade);
    },

  

    btnsearch: function () {
      //console.log(this.Selstatus.);
      // this.sheet=false;
      this.Reload(this.$store.state.eventInfo.EventId);
    
    },

    

  

   

    Reload: async function (event_id) {
      this.loadingstatus = true;
      await this.$axios
        .get(
          "ReportMedia/BasicAccrdReport/" + event_id +  "/" + this.$store.state.eventInfo.VenueId
        )
        .then((res) => {
         // console.log(res.data.result);
          this.desserts = res.data.result;

          this.loadingstatus = false;
        })
        .catch()
        .finally();
    },
  },
};
</script>

<style  scoped>
.tdalign {
  text-align: center;
}
</style>>

